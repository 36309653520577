<template>
    <div>
      <li class="menu">
        <router-link
          :to="{name: 'rendez_vous'}"
          aria-expanded="false"
          class="dropdown-toggle"
        >
          <div class="">
            <i class="las la-calendar" />
            <span>Les Rendez-vous</span>
          </div>
        </router-link>
      </li>
      <li class="menu">
        <router-link
          :to="{name: 'rdv_appointment'}"
          aria-expanded="false"
          class="dropdown-toggle"
        >
          <div class="">
            <i class="las la-calendar" />
            <span>Appointments</span>
          </div>
        </router-link>
      </li>
      <li
        class="menu"
        v-if="is_admin"
      >
        <a
          href="#config_rdv"
          data-toggle="collapse"
          aria-expanded="false"
          class="dropdown-toggle"
        >
          <div class="">
            <i class="las la-cog" />
            <span>Configuration</span>
          </div>
          <div>
            <i class="las la-angle-right sidemenu-right-icon" />
          </div>
        </a>
        <ul
          class="collapse submenu list-unstyled"
          id="config_rdv"
          data-parent="#accordionExample"
        >
          <li>
            <router-link :to="{name: 'admin-time-slot' }">
              Crenaux de RDV
            </router-link>
          </li>
        </ul>
      </li>
    </div>
  </template>
    
    <script>
    import { mapGetters, mapMutations } from 'vuex';
    import { ADD, DELETE, UPDATE } from '../../../constants/app';
    import { SUBSCRIBER_TIME_SLOT } from '../../../graphql/events';
    export default {
        computed: {
            ...mapGetters({
                is_admin: 'auth/is_admin',
            })
        },
        methods: {
          ...mapMutations({
             addTimeSlot: "events/ADD_TIME_SLOT",
             updateTimeSlot: "events/UPDATE_TIME_SLOT",
             deleteTimeSlot: "events/DELETE_TIME_SLOT",
             addAppointment: 'events/ADD_APPOINTMENT',
             updateAppointment: 'events/UPDATE_APPOINTMENT',
             deleteAppointment: 'events/DELETE_APPOINTMENT'
          })
        },
        apollo: {
            $subscribe: {
                time_slot: {
                    query: SUBSCRIBER_TIME_SLOT ,
                    result({data}){
                        let pa = data.time_slot
                        if(pa.type === ADD) this.addTimeSlot(pa.data)
                        if(pa.type === UPDATE) this.updateTimeSlot(pa.data)
                        if(pa.type === DELETE) this.deleteTimeSlot(pa.data)
                    }
                },
                appointment: {
                    query: SUBSCRIBER_TIME_SLOT ,
                    result({data}){
                        let ap = data.appointment
                        if(ap.type === ADD) this.addAppointment(ap.data)
                        if(ap.type === UPDATE) this.updateAppointment(ap.data)
                        if(ap.type === DELETE) this.deleteAppointment(ap.data)
                    }
                },
              
            }
        }
    }
    </script>
    
    <style>
    
    </style>